import axios, { AxiosInstance } from 'axios';

import config from './config';

const getIamHttpClient = () => {
    const accessToken = window.localStorage.getItem('idToken');
    let iamHttpClient: AxiosInstance;
    if (accessToken) {
        iamHttpClient = axios.create({
            baseURL: config.iamApiUrl,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true,
        });
    } else {
        iamHttpClient = axios.create({
            baseURL: config.iamApiUrl,
            withCredentials: true,
        });
    }
    return iamHttpClient;
};

export default getIamHttpClient;
